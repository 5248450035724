<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <base-cropper
          :model="optionsLocal"
          @cropped-image="optionsLocal.logo"
        />
        <!--/ avatar -->
      </b-media-aside>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="accountRules" v-slot="{ invalid }">
      <b-form class="mt-2" @submit.prevent="handleSubmit()">
        <div>
          <b-form-group label="Nombre de usuario" label-for="account-username">
            <validation-provider
              #default="{ errors }"
              name="Nombre"
              rules="required"
            >
              <b-form-input
                v-model="optionsLocal.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Nombre"
                name="username"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div>
          <b-form-group label="Correo electrónico" label-for="account-e-mail">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="optionsLocal.email"
                :state="errors.length > 0 ? false : null"
                name="Email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Número celular para iniciar sesión"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Número de celular"
              rules="required|numeric|digits:10"
            >
              <b-form-input
                v-model="optionsLocal.phone_number"
                name="phone"
                :maxLength="10"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div v-if="optionsLocal.customer" sm="6">
          <b-form-group label="Dirección" label-for="account-address">
            <google-map-form
              :latitude="lat"
              :longitude="lng"
              :zoom="lat && lng ? 10 : 5"
              height="300px"
              @getAddress="setAddressForEdit($event)"
            />
          </b-form-group>

          <div>
            <p class="d-flex flex-column">
              <span class="text-muted">
                ¿La dirección de tu cuenta es correcta?
              </span>
              <span v-if="optionsLocal.customer.address">
                {{ optionsLocal.customer.address.formatted_address }}
              </span>
            </p>
          </div>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
            :disabled="invalid"
          >
            Guardar cambios
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-2"
            @click="resetForm()"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import BaseCropper from "@/@core/components/BaseCropper.vue"
import GoogleMapForm from "@/@core/components/google-map-form.vue"
import AddressFormModel from "@/@core/components/AddressFormModel.vue"

import { getCleanedPhoneNumber } from "@/@core/utils/utils"

import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    AddressFormModel,
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
    GoogleMapForm,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [messagesMixin],
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem("userData")),
      profileFile: null,
      phoneNumber: null,
      lat: null,
      lng: null,
      user: {
        name: null,
        email: null,
        logo: null,
        customer_attributes: {
          id: null,
          address_attributes: null,
        },
      },
    }
  },
  beforeMount() {
    this.resetForm()
  },
  methods: {
    ...mapActions("users", ["editUser"]),
    ...mapMutations("users", ["updateUserData"]),
    resetForm() {
      this.optionsLocal = JSON.parse(localStorage.getItem("userData"))
      this.optionsLocal.phone_number = getCleanedPhoneNumber(
        this.optionsLocal.phone_number
      )

      if (this.optionsLocal.customer) {
        this.lat = Number(this.optionsLocal.customer.address?.lat)
        this.lng = Number(this.optionsLocal.customer.address?.lng)
      }
    },
    setAddressForEdit(x) {
      this.optionsLocal.customer.address = {
        ...x,
        id: this.optionsLocal.customer.address?.id,
      }
    },
    handleSubmit() {
      this.user.name = this.optionsLocal.name
      this.user.email = this.optionsLocal.email
      this.user.logo = this.optionsLocal.logo
      this.user.phone_number = `52${this.optionsLocal.phone_number}`
      this.user.cel_number = `52${this.optionsLocal.phone_number}`
      this.user.customer_attributes.id = this.optionsLocal.customer?.id

      if (this.optionsLocal.customer) {
        this.user.customer_attributes.address_attributes =
          this.optionsLocal.customer.address

        if (this.optionsLocal.customer.address) {
          this.user.customer_attributes.address_attributes.id =
            this.optionsLocal.customer.address.id
        }
      }

      this.editUser({
        id: this.optionsLocal.id,
        user: this.user,
      })
        .then((response) => {
          this.updateUserData(response)

          this.successSwal({
            title: "¡Listo! ",
            text: "Usuario editado!",
          })

          // this.$router.push({ name: "home" })
        })
        .catch((error) => {
          this.errorSwal({
            title: "¡Ups! ",
            text: "Algo salió mal, intenta de nuevo.",
          })
        })
    },
  },
}
</script>
